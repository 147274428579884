// BackToTop.js

import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        isVisible && (
            <div
                className="fixed bottom-4 right-4 cursor-pointer text-white bg-gray-700 p-3 rounded-full hover:bg-gray-600 z-50 shadow-lg"
                onClick={scrollToTop}
            >
                <FaArrowUp size={20} />
            </div>
        )
    );
};

export default BackToTop;
