
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./Properties.css"; // Import your custom CSS if necessary

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/hostaway/listings`);
        setProperties(response.data);
        console.log(properties);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    pauseOnHover: true

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <section className="py-20 bg-gray-100" id="properties">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-10">Check Out Our Properties</h2>
        <Slider {...settings}>
          {properties.map((property, index) => (
            <div key={index} className="p-4">
              <img
                // null check for thumbnailUrl
                src= {property.thumbnailUrl ? property.thumbnailUrl.split('?')[0] : null}
                // src={property.thumbnailUrl: property.thumbnailUrl.split('?')[0] ? property.thumbnailUrl.split('?')[0] : null}
                alt={property.externalListingName}
                className="mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">
                {property.externalListingName}
              </h3>
              <p className="text-yellow-500">
                {[...Array(5)].map((_, i) => (
                  <i key={i} className="fas fa-star"></i>
                ))}
              </p>
              <a
                href={property.airbnbListingUrl}
                className="mt-4 inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Properties;
