import React from "react";

const AboutUs = () => (
  <section className="py-20 bg-white fade-in" id="about-us">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-10">Who Are We?</h2>

      <div className="w-3/4 mx-auto">
        <p className="mb-6">
          Founded by two passionate Ph.D. holders, our exceptional team is
          dedicated to empowering Airbnb hosts with the expertise they need to
          maximize revenue and uphold outstanding customer satisfaction. Through
          our in-house data analysis and cutting-edge automation tools, we offer
          comprehensive asset management services that cater to the unique needs
          of every host.
        </p>

        <p className="mt-6">
          Our passion lies in your success. We go beyond conventional property
          management, offering a partnership that elevates your hosting journey
          to new heights. Join us in redefining the art of hosting on Airbnb.
        </p>
      </div>
    </div>
  </section>
);

export default AboutUs;
