import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/hosting/Header";
import Services from "./components/hosting/Services";
import AboutUs from "./components/hosting/AboutUs";
import ClientReviews from "./components/hosting/ClientReviews";
import Properties from "./components/hosting/Properties";
import GuestReviews from "./components/hosting/GuestReviews";
import FAQ from "./components/hosting/FAQ";
import ContactForm from "./components/hosting/ContactForm";
import Footer from "./components/hosting/Footer";
import BackToTop from "./components/hosting/BackToTop";

function App() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <div className={`app ${fadeIn ? "fade-in" : ""}`}>
      <Header />
      <Services />
      <AboutUs />
      {/* <ClientReviews /> */}
      <Properties />
      {/* <GuestReviews /> */}
      <FAQ />
      {/* <ContactForm /> */}
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
