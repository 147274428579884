import React from "react";

const Services = () => (
  <section className="py-20 bg-gray-100" id="our-service">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-10">How We Can Help</h2>

      <div className="flex justify-around">
        <div className="w-1/3 p-4">
          {/* <img src="https://placehold.co/300x300" alt="Market Analysis" className="mx-auto mb-4" /> */}
          <i className="fas fa-home text-4xl mb-4"></i>
          <h3 className="text-xl font-semibold mb-2">Market Analysis</h3>

          <p>
            We provide consulting services to help you understand your potential
            earning.
          </p>
        </div>

        <div className="w-1/3 p-4">
          {/* <img src="https://placehold.co/300x300" alt="Market Driven Pricing Strategy" className="mx-auto mb-4" /> */}
          <i className="fas fa-shield-alt text-4xl mb-4"></i>
          <h3 className="text-xl font-semibold mb-2">
            Market Driven Pricing Strategy
          </h3>

          <p>
            We update your listing price 3 times a day based on the real-time
            market information.
          </p>
        </div>

        <div className="w-1/3 p-4">
          {/* <img src="https://placehold.co/300x300" alt="AI Powered Automation" className="mx-auto mb-4" /> */}
          <i className="fas fa-star text-4xl mb-4"></i>
          <h3 className="text-xl font-semibold mb-2">AI Powered Automation</h3>

          <p>
            We have AI powered automation system to create a smooth experience
            for both guests and owners.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Services;
