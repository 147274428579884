// Footer.js

import React from "react";

const Footer = () => (
  <footer className="py-10 bg-gradient-to-b from-pink-600 to-indigo-800 text-white">
    <div className="container mx-auto text-center">
      <div className="flex justify-center items-center">
        {/* <img
          src="https://placehold.co/150x50"
          alt="OurBNB Logo"
          className="h-10"
        /> */}
        <span className="ml-2 text-xl font-bold">OurBNB</span>
      </div>

      <div className="mt-4 flex justify-center space-x-4">
        <a href="#" className="text-gray-100 hover:text-white">
          Home
        </a>
        <a href="#" className="text-gray-100 hover:text-white">
          Services
        </a>
        <a href="#" className="text-gray-100 hover:text-white">
          Properties
        </a>
        <a href="#" className="text-gray-100 hover:text-white">
          Contact
        </a>
      </div>

      <div className="mt-4 flex justify-center space-x-4">
        <i className="fab fa-facebook-f text-gray-100 hover:text-white"></i>
        <i className="fab fa-linkedin-in text-gray-100 hover:text-white"></i>
      </div>

      <div className="mt-4">
        <p className="text-gray-100">Email: info@ourbnb.ca</p>
        <p className="text-gray-100">Phone: 1-855-568-7262</p>
      </div>

      <div className="mt-4">
        <p className="text-gray-100">
          &copy; 2024 OurBNB. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
