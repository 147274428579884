// Header.js

import React from "react";

const Header = () => {
  return (
    // Added a gradient background for a modern look
    <header className="bg-gradient-to-r from-indigo-800 via-pink-800 to-indigo-800 bg-cover bg-center">
      <div className="h-full flex flex-col justify-between">
        <nav className="flex justify-between items-center p-6">
          <div className="text-white text-2xl font-bold">OurBNB</div>

          <ul className="flex space-x-6 text-white">
            <li>
              <a href="https://ourbnb.ca/">Home</a>
            </li>
            <li>
              <a href="#our-service">Services</a>
            </li>
            <li>
              <a href="#properties">Properties</a>
            </li>
            <li>
              <a href="#contact-us">Contact</a>
            </li>
          </ul>

          <div className="flex space-x-4 text-white">
            <i className="fab fa-facebook-f"></i>
            <i className="fab fa-linkedin-in"></i>
          </div>
        </nav>

        <div className="text-center text-white mb-20">
          <h1 className="text-5xl font-bold">OurBNB</h1>
          <p className="text-xl mt-4">
            Leading Calgary's Short Term Rental Market
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
