import React from "react";

const FAQ = () => (
  <section class="py-16 bg-white">
    <div class="container mx-auto text-center">
      <h2 class="text-3xl font-bold mb-8">Frequently Asked Questions</h2>
      <div class="max-w-2xl mx-auto text-left">
        <div class="mb-4">
          <h3 class="text-xl font-semibold">
            1. What is a Short-Term Rental (STR)?
          </h3>
          <p class="mt-2">
            A short-term rental is a furnished living space available for short
            periods, typically less than a month.
          </p>
        </div>
        <div class="mb-4">
          <h3 class="text-xl font-semibold">2. Overview of OurBNB</h3>
          <p class="mt-2">
            OurBNB is a short term rental solution provide based in Calgary. We
            customized the solution and design the strategy for each individual
            property.
          </p>
        </div>
        <div class="mb-4">
          <h3 class="text-xl font-semibold">
            3. I am not sure if my property will do well as a short-term rental
            (STR), how can you help?
          </h3>
          <p class="mt-2">
            We provide a free consultation to assess your property's potential
            and offer recommendations to maximize its rental income.
          </p>
        </div>
        <div class="mb-4">
          <h3 class="text-xl font-semibold">4. How much could I earn?</h3>
          <p class="mt-2">
            Earnings vary based on location, property type, and market demand.
            We provide a detailed earnings estimate during our consultation.
          </p>
        </div>
        <div class="mb-4">
          <h3 class="text-xl font-semibold">
            5. How is the management fee calculated?
          </h3>
          <p class="mt-2">
            Our management fee is a percentage of the nightly rate, ensuring we
            only get paid when you get paid.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default FAQ;
